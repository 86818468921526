<template>
  <q-banner
    rounded
    class="flex justify-start items-center no-wrap bg-gray-white text-black"
    :class="{
      'shadow-default': !flat,
      'q-card--bordered': bordered
    }"
  >
    <template v-slot:avatar>
      <q-icon :name="icon.name" :color="icon.color" />
    </template>
    <div>
      <b class="q-pb-sm">
        {{ title }}
      </b>
      <p>
        {{ message }}
      </p>
    </div>
  </q-banner>
</template>

<script>
export default {
  name: "PageBanner",
  props: {
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      let icon = {
        name: "sym_r_info",
        color: "primary"
      };
      if (this.type === "critical") {
        icon = {
          name: "sym_r_error",
          color: "negative"
        };
      }
      return icon;
    }
  }
};
</script>

<style scoped lang="scss"></style>
