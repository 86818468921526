<template>
  <q-dialog :ref="$options.name" persistent>
    <q-card>
      <q-card-section class="flex items-center justify-start">
        <q-icon
          v-if="icon.length > 0"
          :name="icon"
          color="primary"
          size="45px"
          class="q-mr-sm"
          :class="iconClass"
        />
        <div>
          <h5 v-html="header" />
          <p
            v-if="subHeader.length > 0"
            v-html="subHeader"
            class="text-font-secondary"
          />
        </div>
      </q-card-section>

      <q-card-section class="q-pt-none" v-html="message" />

      <slot name="content" />

      <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
        <span v-if="hideSecondaryButton"></span>
        <q-btn
          v-else
          flat
          @click="handleCancelClick"
          :label="secondaryButtonLabel"
          color="subtle"
          v-close-popup
          :disable="submitting"
        />

        <span v-if="hidePrimaryButton"></span>
        <q-btn
          v-else
          @click="handleOkClick"
          :label="buttonLabel"
          color="primary"
          :disable="submitting"
          :loading="submitting"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    header: {
      type: String,
      required: true
    },
    subHeader: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    iconClass: {
      type: String,
      default: ""
    },
    buttonLabel: {
      type: String,
      default: "Confirm"
    },
    secondaryButtonLabel: {
      type: String,
      default: "Cancel"
    },
    hideSecondaryButton: {
      type: Boolean,
      default: false
    },
    hidePrimaryButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ["ok", "hide"],
  data() {
    return {
      submitting: false
    };
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    },
    handleOkClick() {
      this.$emit("ok", this);
      // this.hide();
    },
    handleCancelClick() {
      this.$emit("hide", this);
      // this.hide();
    }
  }
};
</script>

<style scoped lang="scss"></style>
