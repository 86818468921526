import Dialog from 'quasar/src/plugins/Dialog.js';;
import ConfirmationModal from "@/components/UI/ConfirmationModal.vue";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete.vue";
import axios from "axios";
import AdvertiserInventorySourcesInfoSidePanel
  from "@/components/Advertiser/AdvertiserInventorySourcesInfoSidePanel.vue";
import store from "@/store";

export default {
  data() {
    return {};
  },
  methods: {
    promptRunSourceManualImport(source) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModal,
          componentProps: {
            header: "Confirm Run Import",
            subHeader: source.name + " - " + source.id,
            message:
              "You're about to run a manual import for this source. This will immediately run a new import of its " + store.state.productTypeConfigs.type + "s. <b>Are you sure you want to continue?</b>",
            buttonLabel: "Run Manual Import"
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("inventorySources/POST_REQUEST", {
              endpoint: "import",
              params: { id: source.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("save", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("save", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    },
    promptPauseSource(source) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModal,
          componentProps: {
            header: "Confirm Pause Imports",
            subHeader: source.name + " - " + source.id,
            message:
              "You're about to pause imports for this source. The associated " + store.state.productTypeConfigs.type + "s will remain active on the network until the source is deactivated or imports are resumed. <b>Are you sure you want to continue?</b>",
            buttonLabel: "Pause Imports"
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("inventorySources/POST_REQUEST", {
              endpoint: "pause",
              params: { id: source.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("save", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("save", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    },
    promptDeactivateSource(source) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModal,
          componentProps: {
            header: "Confirm Deactivation",
            subHeader: source.name + " - " + source.id,
            message:
              "You're about to deactivate this source. We will stop running imports for this source, and its " + store.state.productTypeConfigs.type + "s will be deactivated and removed from the network. <b>Are you sure you want to continue?</b>",
            buttonLabel: "Deactivate Source"
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("inventorySources/POST_REQUEST", {
              endpoint: "deactivate",
              params: { id: source.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("save", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("save", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    },
    promptResumeSource(source) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModal,
          componentProps: {
            header: "Confirm Resume Imports",
            subHeader: source.name + " - " + source.id,
            message:
              "You're about to resume imports for this source. It will begin importing again at the next scheduled daily import time. <b>Are you sure you want to continue?</b>",
            buttonLabel: "Resume Imports"
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("inventorySources/POST_REQUEST", {
              endpoint: "unpause",
              params: { id: source.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("save", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("save", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    },
    promptActivateSource(source) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModal,
          componentProps: {
            header: "Confirm Activation",
            subHeader: source.name + " - " + source.id,
            message:
              "You're about to activate this source. All previous settings will be restored, and we'll immediately run a new import of its " + store.state.productTypeConfigs.type + "s. <b>Are you sure you want to continue?</b>",
            buttonLabel: "Activate Source"
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("inventorySources/POST_REQUEST", {
              endpoint: "activate",
              params: { id: source.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("save", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("save", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    },
    promptDeleteSource(source) {
      return new Promise(resolve => {
        Dialog.create({
          component: ConfirmationModalDelete,
          componentProps: {
            descriptor: source.name + " - " + source.id
          }
        }).onOk(dialog => {
          dialog.submitting = true;
          this.$store
            .dispatch("inventorySources/POST_REQUEST", {
              endpoint: "delete",
              params: { id: source.id }
            })
            .then(() => {
              this.triggerActionOutcomeAlert("delete", true);
              dialog.hide();
              resolve();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert("delete", false);
            })
            .finally(() => {
              dialog.submitting = false;
            });
        });
      });
    },
    performSourceManualUpload(id, file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("id", id);
        formData.append("file", file);
        axios.post(
          process.env.VUE_APP_API_BASE_URL + "/network/app/inventory-sources/manual-upload",
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("save", true);
            resolve();
          })
          .catch(() => {
            // this.triggerActionOutcomeAlert("save", false);
            reject();
          });
      });
    },
    promptSourcesInfoSidePanel() {
      Dialog.create({
        component: AdvertiserInventorySourcesInfoSidePanel
      });
    },
  }
};
