<template>
  <router-link
    :to="to"
    class="link text-weight-regular text-font-secondary flex items-center"
  >
    <q-icon
      v-if="icon && icon.length > 0"
      :name="icon"
      size="15px"
      style="margin-right: 2px"
    />
    {{ label }}
  </router-link>
</template>

<script>
export default {
  name: "Link",
  props: {
    to: {
      required: true
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
.link {
  @include transition(color);

  font-size: 0.875em;
  width: fit-content;

  &:hover {
    color: color(gray) !important;
  }
}
</style>
