<template>
  <Loader v-if="loading" class="q-mt-xl" />
  <div v-else>
    <PageTitlebar :title="'Edit Inventory Source - ' + inventorySourceId">
      <template v-slot:top>
        <Link
          :to="{
            name: 'AdvertiserAccountsOverview',
            query: { advertiser_account_id: advertiserAccountId }
          }"
          label="Back to Sources"
          icon="sym_r_chevron_left"
          class="q-mb-sm"
        />
      </template>
    </PageTitlebar>

    <AdvertiserInventorySourceForm
      type="edit"
      :advertiser-account-id="advertiserAccountId"
      :inventory-source-id="inventorySourceId"
      :submitting="submitting"
      @submit="editInventorySource"
    />
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import Link from "@/components/UI/Link";
import AdvertiserInventorySourceForm from "@/components/Advertiser/AdvertiserInventorySourceForm";
import Loader from "@/components/UI/Loader.vue";
import InventorySourcesMixin from "@/mixins/Advertiser/InventorySourcesMixin";

export default {
  name: "InventorySourceEditor",
  mixins: [InventorySourcesMixin],
  props: {
    advertiserAccountId: {
      type: [Number, String],
      required: true
    },
    inventorySourceId: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    Loader,
    AdvertiserInventorySourceForm,
    Link,
    PageTitlebar
  },
  data() {
    return {
      loading: true,
      submitting: false,
      advertiserAccount: null
    };
  },
  watch: {},
  mounted() {
    this.$store
      .dispatch("advertiserAccounts/GET_REQUEST", {
        params: {
          id: this.advertiserAccountId
        }
      })
      .then(data => {
        if (data.length > 0) {
          this.advertiserAccount = data[0];
        }
      })
      .catch(e => {
        console.log(e);
        this.$router.push({ name: "AdvertiserAccountsOverview" });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    editInventorySource(formData) {
      this.submitting = true;

      this.$store
        .dispatch("inventorySources/POST_REQUEST", {
          endpoint: "update",
          params: {
            id: this.inventorySourceId,
            advertiser_account_id: this.advertiserAccountId,
            name: formData.name,
            source_type: formData.sourceType,
            protocol: formData.protocol,
            host: formData.host,
            is_compressed: formData.isCompressed,
            compressed_file_type: formData.compressedFileType,
            compressed_filename: formData.compressedExtractedFilePath,
            file_type: formData.fileType,
            filename: formData.filePath,
            import_time: formData.utcImportTime,
            username: formData.username,
            password: formData.password,
            static_postal_code: formData.defaultPostalCode,
            static_payable_radius: formData.defaultPayableRadius,
            is_facebook_catalog: formData.isFacebookCatalog
          }
        })
        .then(() => {
          let successPromiseChain = [];
          if (formData.sourceType === "manual_upload") {
            successPromiseChain.push(this.performSourceManualUpload(this.inventorySourceId, formData.manualUploadFile));
          }

          Promise.all(successPromiseChain)
            .then(() => {
              this.triggerActionOutcomeAlert("save", true);
              this.backToTop();
            })
            .catch(() => {
              this.triggerActionOutcomeAlert(
                "save",
                false,
                "Something went wrong while uploading your file. Please ensure the file is valid and try again."
              );
            })
            .finally(() => {
              this.submitting = false;
            });
        })
        .catch(e => {
          console.log(e);
          if (e.response) {
            this.triggerActionOutcomeAlert(
              "save",
              false,
              e.response.data.errors[0].detail
            );
          } else {
            this.triggerActionOutcomeAlert("save", false);
          }
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
