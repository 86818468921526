<template>
  <SidePanel header="Inventory Sources">
    <template v-slot:content>
      <!--<p>
        Selecting the right product type for your account is important. We
        recommend that you choose the most specific product type possible, to
        help us serve your inventory to the most appropriate visitors.
        <br /><br />Additionally, providing high quality information about your
        products allows us to better serve them to the visitors that are most
        likely to convert. <br /><br />If you want more control of how your
        products are served, you can provide product specific
        <span class="text-font-secondary">`bid_value`</span> and
        <span class="text-font-secondary">`payable_radius`</span> fields.
        <br /><br />Below is a complete list of possible product types, and the
        fields that should be provided for each:
      </p>-->
      <p>
       Inventory Sources info...
      </p>
    </template>
  </SidePanel>
</template>

<script>
import SidePanel from "@/components/UI/SidePanel";

export default {
  name: "AdvertiserInventorySourcesInfoSidePanel",
  components: { SidePanel },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
